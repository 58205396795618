
.notifications,
.notifications__item {
  padding: 0;
  margin: 0;
}

.notifications {
  position: fixed;
  z-index: 100;
  top: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 1rem;
  justify-content: flex-end;
}

.notifications__item {
  width: 300px;
  background: var(--mainColor);
  color: var(--textColor);
  border: 0.2rem var(--textColor) solid;
  flex: 0 0 100px;
  position: relative;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
  gap: 1rem;
}
.notifications__item h3{
  font-size: 1.45rem;
}
.notifications__item p{
  font-size: 1.2rem;
}
.notifications__item--close{
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  color: var(--textColor);
  border: none;
  border-radius: 0.5rem;
  font-size: larger;
  cursor: pointer;
  outline: none;
}
.notifications__item--close circle,
.notifications__item--close rect,
.notifications__item--close line {
  fill: transparent;
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: round;
}
