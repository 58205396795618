.viewUser{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 0 10px 0;
}
.containerImg{
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 1rem;
}
.containerImg img{
    width: 200px;
}
.personalInformation{
    width: 600px;
    text-align: center;
    position: relative;
}
.backgroundForText{
    margin: 0 auto;
    width: 500px;
    height: 50px;
    display: flex;
    z-index: 99;
    position: relative;
    border-radius: 15px;
    -webkit-filter: drop-shadow(1px 1px 2px rgba(255, 110, 196, 0.5))
    drop-shadow(-1px -1px 2px rgba(66, 230, 149, 0.5));
    filter: drop-shadow(1px 1px 2px rgba(255, 110, 196, 0.5))
    drop-shadow(-1px -1px 2px rgba(66, 230, 149, 0.5));
    place-content: center;
    overflow: hidden;
    place-items: center;
}
.backgroundForText::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1000%;
    background: conic-gradient(
         from 0deg,
         #ff6ec4,
         #7873f5,
         #42e695,
         #ff6ec4
     );
    animation: rotateRing 3s linear infinite;
}
.backgroundForText::after {
    content: '';
    position: absolute;
    background: var(--backgroundText);
    inset: 2px;
    border-radius: 17px;
}
.Name{
    position: relative;
    font-size: 2.8rem;
    z-index: 1;
    color: var(--textColor)
}
.whoIam{
    margin: 60px 0 30px 0;
    font-size: 1.5rem;
}
.whoIam p{
    margin: 10px;
}
.descriptionAboutMe{
    width: 600px;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
}
.descriptionAboutMe .wordProfile, .descriptionAboutMe .wordWork, .descriptionAboutMe .Hobbies, .descriptionAboutMe .Skills{
    margin: 20px 0;
    font-size: 2rem;
    border-bottom: 3px solid var(--outlineColor);
}
.wordProfile{
    width: 65px;
}
.wordWork{
    width: 160px;
}
.Hobbies{
    width: 80px;
}
.Skills{
    width: 50px;
}
.descriptionAboutMe .textForDescription{
    padding-left: 30px;
}
.workDescription li{
    padding-top: 1rem;
    font-size: bold;
}
.linkToWorks{
    background-color: #FD367E;
    border: none;
    border-radius: 5px;
    transition: all 0.3 ease-in;
    width: 100px;
    height: 40px;
    outline: 1px solid #03C4A100;
    transition: all 0.1s ease-in;
}
.linkToWorks a{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100px;
    font-size: 2rem;
    color: var(--textColor);
    text-decoration: none;
}
.linkToWorks:focus{
    outline: 2px solid var(--outlineColor);
}

.item {
    width: 56%;
    overflow: visible;
    stroke: #fff;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-linecap: round;
}
.cls-1{
    fill:none;
    stroke:#fff;
    stroke-miterlimit:10;
    stroke-width:9px;
}

@media only screen and (max-width: 600px) {
    .personalInformation{
        width: 90%;
    }
    .descriptionAboutMe{
        width: 90%;
    }
    .backgroundForText{
        width: 90%;
    }
    .backgroundForText::before {
        height: calc(100vw - 20px);
    }
    .Name{
        font-size: 1.875rem;
    }
}