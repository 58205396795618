.container{
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: end;
  flex-direction: column;
  max-width: 900px;
  font-weight: 400;
  font-style: normal;
  position: relative;
}
.messages {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding: 50px 0 0 0;
  overflow-x: hidden;
}
.messages .inputEmail{
  margin: 10px 0 10px 0;
  width: calc(100% - 20px);
}

.messages  .messages_loader {
  padding-top: 60px;
}
