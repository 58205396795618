:root{
    --fire-spinner-margin : #1a73e8;
  }
  
  .containerLoad {
    margin-bottom: 0;
    background: transparent;
    align-items: center;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    min-height: 100%;
    top: 0;
    width: 100%;
    z-index: 6;
  }
  .mat-progress-spinner {
    display: block;
    position: relative;
    overflow: hidden;
  }
  fire-spinner mat-spinner {
    margin: var(--fire-spinner-margin, 24px);
  }
  .mat-progress-spinner svg {
    position: absolute;
    transform: rotate(-90deg);
    top: 0;
    left: 0;
    transform-origin: center;
    overflow: visible;
    animation: girar 2000ms linear infinite;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  .circle-rotate {
    transition-property: stroke;
    animation-duration: 4000ms;
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-iteration-count: infinite;
    fill: transparent;
    transform-origin: center;
    transition: stroke-dashoffset 225ms linear;
    animation-name: mat-progress-spinner-stroke-rotate-56; 
    stroke-dasharray: 144.513px; 
    stroke-width: 10%;
  }
  @keyframes mat-progress-spinner-stroke-rotate-56 {
    0% {
      stroke-dashoffset: 137.28759896187395;
      transform: rotate(0);
    }
    12.5% {
      stroke-dashoffset: 28.902652413026097;
      transform: rotate(0);
    }
    12.5001% {
      stroke-dashoffset: 28.902652413026097;
      transform: rotateX(180deg) rotate(72.5deg);
    }
    25% {
        stroke-dashoffset: 137.28759896187395;
        transform: rotateX(180deg) rotate(72.5deg);
    }
    25.0001% {
        stroke-dashoffset: 137.28759896187395;
        transform: rotate(270deg);
    }
    37.5% {
        stroke-dashoffset: 28.902652413026097;
        transform: rotate(270deg);
    }
    37.5001% {
        stroke-dashoffset: 28.902652413026097;
        transform: rotateX(180deg) rotate(161.5deg);
    }
    50% {
        stroke-dashoffset: 137.28759896187395;
        transform: rotateX(180deg) rotate(161.5deg);
    }
    50.0001% {
        stroke-dashoffset: 137.28759896187395;
        transform: rotate(180deg);
    }
    62.5% {
        stroke-dashoffset: 28.902652413026097;
        transform: rotate(180deg);
    }
    62.5001% {
        stroke-dashoffset: 28.902652413026097;
        transform: rotateX(180deg) rotate(251.5deg);
    }
    75% {
        stroke-dashoffset: 137.28759896187395;
        transform: rotateX(180deg) rotate(251.5deg);
    }
    75.0001% {
        stroke-dashoffset: 137.28759896187395;
        transform: rotate(90deg);
    }
    87.5% {
        stroke-dashoffset: 28.902652413026097;
        transform: rotate(90deg);
    }
    87.5001% {
        stroke-dashoffset: 28.902652413026097;
        transform: rotateX(180deg) rotate(341.5deg);
    }
    100% {
        stroke-dashoffset: 137.28759896187395;
        transform: rotateX(180deg) rotate(341.5deg);
    }
  }
  
  @keyframes girar {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }