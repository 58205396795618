.viewContact{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}
.followMe{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: fit-content;
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-bottom: 3rem;
}
.inputEmail, .inputName , .textareaEmail, .buttonSend{
    background-color: transparent;
    padding: 1rem;
    border: white 0.2rem solid;
    border-radius: 5px;
    width: 300px;
    height: 40px;
    color: white;
    font-size: 1.5rem;
    outline-offset: 0.2rem;
}
.textareaEmail{
    height: 100px;
}
.buttonSend{
    width: 100px;
    background-color: var(--pinkColor);
    border: none;
}
.inputEmail:focus, .textareaEmail:focus, .buttonSend:focus{
    outline: 2px solid var(--outlineColor);
}
@media only screen and (max-width: 600px) {
    .viewContact{
        min-height: 65rem;
    }
}