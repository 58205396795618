:root{
    --ladoX: 100px;
    --ladoY: 100px;
    --esquina: 50%;
}

.scrollAndAlertMessages_container {
    position: relative;
    width: 100%;
}
.scrollAndAlertMessages_button {
    height: 34px;
    right: 10px;
    bottom: 10px;
    z-index: 100;
    outline: none;
    border: none;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--backgroundText);
    border-radius: 17px;
    color: var(--textColor);
    cursor: pointer;
    z-index: 101;
    place-content: center;
    place-items: center;
    overflow: hidden;
    svg{
        z-index: 1;
    }
}

.scrollAndAlertMessages_button.ring::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 400%;
    background: conic-gradient(
         from 0deg,
         #ff6ec4,
         #7873f5,
         #42e695,
         #ff6ec4
     );
    animation: rotateRing 3s linear infinite;
  }
.scrollAndAlertMessages_button.ring::after {
    content: '';
    position: absolute;
    background: var(--backgroundText);
    inset: 2px;
    border-radius: 17px;
} 
.scrollAndAlertMessages_button.ring p{
    padding-left: 3px;
    z-index: 1;
}
  
@keyframes rotateRing {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}