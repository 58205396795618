.message-ui {
    font-size: 1.5rem;
    position: relative;
    /* Para forzar salto de línea cuando se exceda la max-width */
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #f4f4f4;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    /* font-family:  SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace; */
    line-height: 1.5;
    white-space: pre-wrap;
    word-wrap: break-word;
    
}

.message-ui .emoji {
    font-family: 'Noto Color Emoji', sans-serif;
    font-size: 1.8rem;
    vertical-align: middle;
}

.message-ui a {
    color: cyan;
    text-decoration: none;
}

.message-ui a:hover {
    text-decoration: underline;
}

.message{
    display: flex;  
    margin: 0.2rem 0;
    position: relative;
    flex-direction: column;
    background-color: #505050;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    max-width: 80%; /* o calc(85% - 25px), según tu preferencia */
    border: none;
    width: fit-content;
}
.message.to, .message.to .username{
    color: #f8a59a;
    left: 30px;
    align-items: start;
}
.message.from, .message.from .username{
    align-self: end;
    color: #a4eef9;
    right: 30px;
}
.message.from::before{
    right: -7px;
    transform: rotateZ(298deg);     
}
.message.to::before{
    transform: rotateZ(60deg);     
    left: -7px;                     /* Ajustar para posicionar horizontalmente */
}
.message.otherUser::before {
    top: -6px;                       /* Ajustar para posicionar verticalmente */
    z-index: 9;
    content: '';                     /* Necesario para mostrar el pseudo-elemento */
    position: absolute;              /* Posicionamiento absoluto relativo a la caja */
    background-color: #505050;          /* Color de fondo del pseudo-elemento */
    width: 20px;
    height: 20px;
    border-radius: 50%;
    clip-path: path('M 10 2 Q 11 6 20 20 Q 10 12.5 0 20 Q 7.5 7.5 10 0 Z');
}
.message .datetime{
    color: #e2e2e2;
    font-size: xx-small;
    align-self: end;
    padding-top: 0.1rem;
}
  