.viewWorks{
    width: 100%;
    overflow: hidden;
    padding: 50px 0 10px 0;
    margin: 0;
    position: relative;
}
.containerListWorks{
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    position: relative;
    margin-bottom: 2rem;
}
.containerListWorks .cardWork{
    width: 25rem;
    height: 25rem;
    overflow: hidden;
    border: 0.2rem solid rgb(112, 112, 112);
    border-radius: 0.5rem;
    color: var(--textColor);
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}
.cardWork__figure{
    height: 17rem;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.cardWork__figure--img{
    object-position: center center;
    object-fit: cover;
    height: 100%;
}
@supports(object-fit: cover){
    .cardWork__figure--img{
        object-position: center center;
        object-fit: cover;
    }
}
.cardWork__description{
    width: 100%;
    padding: 1rem;
    height: 8rem;
}
.cardWork__description--title{
    padding-bottom: 0.5rem;
}
.cardWork__description--p{
    font-size: small;
    overflow: hidden;
    position: relative;
    mix-blend-mode: hard-light;
    height: 100%;
}
.cardWork__description--p::after {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, transparent 0%, var(--mainColor) 50%);;
    pointer-events: none;
} 
.containerWork{
    margin-top: 5.5rem;
    width: 100%;
    height: calc(100vh - 8.5rem);
    position: fixed;
    top: 0;
    overflow: hidden;
    display: grid;
    grid-template-columns: 2fr 1fr;
}
.containerWork__images{
    position: relative;
    height: inherit;
    display: grid;
    grid-template-rows: 75% 25%;
    justify-items: center;
    align-items: center;
}
.containerWork__images--template{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.imgForSlides {
    position: absolute;
    cursor: grab;
    border-radius: 10px;
    max-height: 100%;
    max-width: 100%;
}
.imageShow{
    height: 120px;
    max-width: inherit;
    overflow: hidden;
}
.imageShow img{
    height: 100%;
    max-width: 250px;
    object-fit: cover;
    border-radius: 10px;
    padding: 0 5px;
}
@supports(object-fit: cover){
    .imageShow img{
      object-fit: cover;
      object-position: center center;
    }
}
.containerDetails{
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}
.textDetail{
    text-align: center;
}
.nameWork{
    font-size: 2.8rem;
    color: var(--textColor);
    margin-bottom: 5px;
}
.descriptionWork{
    font-size: 1.3rem;
    color: var(--textColor);
    margin-bottom: 5px;
}
.buttonDetails{
    color: var(--textColor);
    font-size: 1.6rem;
    cursor: pointer;
    z-index: 400;
    margin: 2rem 0;
}
@media only screen and (max-width: 600px) {
    .containerWork{
        grid-template-columns: 1fr;
        height: inherit;
    }
    .containerWork__images{
        grid-template-rows: 1fr 1fr;
        height: fit-content;
    }
    .containerWork__images--template{
        width: 100%;
        height: 18rem;
    }
    .imageShow{
        max-width: 100%;
        display: flex;
    }
    .containerListWorks .cardWork{
        width: 95%;
        height: 10rem;
        overflow: hidden;
        justify-content: center;
        flex-direction: row;
    }
    .cardWork__figure{
        height: 100%;
        width: 85%;
    }
}