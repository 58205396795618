
.input {
    width: 100%;
    display: grid;
    gap: 3px;
    padding: 8px 6px 5px 6px;
    grid-template-columns: 1fr 40px;
  }
.input .send {
    align-self: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 3px;
    height: 38px;
    width: 38px;
    background-color: var(--textColor);
}