.Header{
    height: 50px;
    width: 100%;
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    top: 0;
    backdrop-filter: blur(10px);
    z-index: 40;
    overflow: visible
}

.Header .figureLogo{
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-left: 10px;
}
.Header .Logo{
    color: var(--textColor);
    font-size: 2.8rem;
}
.Header .navigate{
    display: flex;
    list-style: none;
    justify-content: space-between;
    width: 420px;
    height: 80%;
}
.Header .navigate li{
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    color: var(--textColor);
    border: 3px solid transparent;
    transition: all 0.2s, ease;
    cursor: pointer;
}
.Header .navigate a{
    text-decoration: none;
    outline: 1px solid #03c4a100;
    transition: all 0.1s ease-in;
    border-radius: 2px;
}
.Header .navigate a:focus{
    outline: 1px solid #03C4A1;
}

.Header .navigate li:hover{
    border-bottom: 3px solid var(--textColor);
}
.Header .octicon-mark-github{
    fill: currentColor;
    padding-right: 6px;
}