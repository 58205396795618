.AutoSizeTextArea{
    background-color: transparent;
    border: white 2px solid;
    border-radius: 5px;
    width: 100%;
    color: white;
    font-size: 1.5rem;
    outline-offset: 0.2rem;
    padding: 0.8rem 1rem;
}
.AutoSizeTextArea:focus{
    outline: 2px solid var(--outlineColor);
}