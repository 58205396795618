.Footer{
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    backdrop-filter: blur(10px);
    z-index: 100;
    font-size: 1.2rem;
}
.Footer.chatApp{
    display: none;
}
.Footer__text{
    color: var(--textColor);
}