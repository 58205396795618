.ContainerSocialNetworks{
    height: 100px;
    width: 550px;
    display: grid;
    grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
}
.containerIcon{
    display: flex;
    margin: 10px 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--textcolor);
    text-decoration: none;
    outline-offset: 0.5rem;
    border-radius: 0.5rem;
}
.containerIcon:focus{
    outline: 2px solid var(--outlinecolor);
}
.containerIcon img, .containerIcon svg{
    width: 100%;
    height: 100%;
}
.containerIcon .textIco{
    font-size: 1.5rem;
    justify-self: baseline;
}

@media only screen and (max-width: 600px) {
    .ContainerSocialNetworks{
        height: fit-content;
        width: 300px;
        grid-template-columns: 33% 33% 33%;
    }
}