.love-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 70px);
    background-color: #1a1a1b;
    color: white;
    position: relative;
    overflow: hidden;
  }
  
  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60%;
    padding: 20px;
    text-align: center;
    user-select: none;
  }
  
  .options {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    font-size: 1.2rem;
    color: #cccccc;
  }
  .options .left {
    justify-self: start; /* Alinea a la izquierda */
  }
  
  .options .right {
    justify-self: end; /* Alinea a la derecha */
  }
  
  .options span {
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .options span:hover {
    color: white;
  }
  
  .final-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  